"use strict";

import "regulus-oscar";


// Brand filtering on product category pages
$(function(){
    // Noddy search functionality on product category page
    var filterInput = $('#product-category-search');

    var filterProducts = function() {
        // Find out whether a filter is active
        var brand = $('.js-brand-filter.active').data('brand');
        var text = filterInput.val().toUpperCase();

        if (brand) {
            $('.js-product-group').removeClass('d-none');
            $('.js-branded-product').addClass('d-none').filter("[data-brand='" + brand + "']").removeClass('d-none');
            $('.js-brand-filter-title').removeClass('d-none').find('span').text(brand);
        }
        else {
            $('.js-product-group, .js-branded-product').removeClass('d-none');
            $('.js-brand-filter-title').addClass('d-none');
        }

        if(text) {
            $('.js-branded-product').each(function(i, el){
                var title = $(el).find('.js-title').text().toUpperCase();
                if(title.indexOf(text) === -1) {
                    $(el).addClass('d-none');
                }
            });
        }

        // Hide any categories that are now empty
        $('.js-product-group').each(function(){
            if($(this).find('.js-branded-product').not('.d-none').length == 0) {
                $(this).addClass('d-none');
            }
        });
    }

    // Hide brands that have no products on the page at all
    $('.js-brand-filter').each(function(i, el){
        var brand = $(el).data('brand');
        if(!$('.js-branded-product').filter("[data-brand='" + brand + "']").length) {
            $(el).addClass('d-none');
        }
    });

    $('.js-brand-filter').click(function(e){
        var el = $(this);
        el.toggleClass('active');
        if (el.hasClass('active')) {
            el.siblings().removeClass('active');
        }
        filterProducts();
    });

    $('.js-clear-brand-filter').click(function(){
        $('.js-brand-filter').removeClass('active');
        filterProducts();
    });


    // Product image carousel cycles only on hover
    $(".js-branded-product").on("mouseenter",function() {
        $(this).find('.carousel').carousel('cycle');
    }).on("mouseleave", function() {
        $(this).find('.carousel').carousel('pause');
    });

    filterInput.on('keyup', filterProducts);
});
